import urls from '@/constants/endpoints/performanceLog';
import {PersonnelBasicDTO} from '@/types/gatewayDataModels';
import {
    AssignReviewerDTO,
    AssignReviewerResponseDTO,
    CloseLogsDTO,
    CommentDTO,
    CreateCommentDTO,
    CreateOrUpdatePerformanceImprovementLogDTO,
    CreateOrUpdateReviewDTO,
    EnumObjectDTO,
    LogChangeDTO,
    LogCountDTO,
    LogStatus,
    PerformanceImprovementLogDTO,
    PerformanceImprovementLogPageDTO,
    PerformanceImprovementLogPageRequestDTO,
    SubmissionTypes,
} from '@/types/performanceIssueTrackerDataModels';

import httpApi from './httpApi_new';

class PerformanceImprovementLogService {
    getPerformanceImprovementLogs = ({
        queryParams,
        data,
    }: {
        queryParams: {page?: number; size?: number; sort?: string};
        data: PerformanceImprovementLogPageRequestDTO;
    }): Promise<PerformanceImprovementLogPageDTO> =>
        httpApi.post({
            url: urls.PERFORMANCE_IMPROVEMENT_LOG,
            queryParams,
            data,
        });

    getImprovementLogEventTypes = (): Promise<EnumObjectDTO[]> => httpApi.get({url: urls.IMPROVEMENT_LOG_STATES});
    getImprovementLogCounter = (): Promise<LogCountDTO> =>
        httpApi.get({
            url: urls.IMPROVEMENT_LOG_COUNTER,
        });
    addComment = (data: CreateCommentDTO): Promise<CommentDTO> =>
        httpApi.post({
            url: urls.ADD_COMMENTS_TO_HISTORY_IMPROVEMENT_LOG,
            data,
        });
    getCommentsHistory = (id: string): Promise<CommentDTO[]> =>
        httpApi.get({
            url: urls.COMMENTS_HISTORY_IMPROVEMENT_LOG(id),
        });
    getCommentsHistoryCounter = (id: string, disableGeneralNotify?: (value: unknown) => boolean): Promise<number> =>
        httpApi.get({
            url: urls.COMMENTS_HISTORY_IMPROVEMENT_LOG_COUNTER(id),
            disableGeneralNotify,
        });
    getPatientImprovementLogCounter = (patientId: string): Promise<LogCountDTO> =>
        httpApi.get({
            url: urls.PATIENT_IMPROVEMENT_LOG_COUNTER(patientId),
        });
    saveDraft = ({
        sendAlertEmail,
        ...data
    }: CreateOrUpdatePerformanceImprovementLogDTO & {
        sendAlertEmail?: boolean;
    }): Promise<PerformanceImprovementLogDTO> =>
        httpApi.post({
            url: urls.IMPROVEMENT_LOG_SAVE_DRAFT,
            data,
            queryParams: {...(sendAlertEmail === false ? {sendAlertEmail: false} : {})},
        });
    editOriginalData = (
        data: CreateOrUpdatePerformanceImprovementLogDTO,
        id: string,
        queryParams: {sendAlertEmail: boolean}
    ): Promise<PerformanceImprovementLogDTO> =>
        httpApi.put({
            url: urls.IMPROVEMENT_LOG_ORIGINAL_DATA(id),
            data,
            queryParams,
        });
    editReviewData = (
        data: CreateOrUpdatePerformanceImprovementLogDTO,
        id: string
    ): Promise<PerformanceImprovementLogDTO> =>
        httpApi.put({
            url: urls.IMPROVEMENT_LOG_REVIEW_DATA(id),
            data,
        });
    saveReview = (data: CreateOrUpdateReviewDTO, id: string): Promise<PerformanceImprovementLogDTO> =>
        httpApi.post({
            url: urls.IMPROVEMENT_LOG_SAVE_REVIEW(id),
            data,
        });
    closeLogs = (data: CloseLogsDTO, id: string): Promise<PerformanceImprovementLogDTO> =>
        httpApi.put({
            url: urls.IMPROVEMENT_LOG_CLOSE_LOGS(id),
            queryParams: data,
        });
    getLogEntryById = (
        id: string,
        disableGeneralNotify?: (value: unknown) => boolean
    ): Promise<PerformanceImprovementLogDTO> =>
        httpApi.get({
            url: urls.SINGLE_IMPROVEMENT_LOG(id),
            disableGeneralNotify,
        });
    getResponsibleActors = (): Promise<EnumObjectDTO[]> =>
        httpApi.get({
            url: urls.IMPROVEMENT_LOG_GET_RESPONSIBLE_ACTORS,
        });
    getCausalFactors = (): Promise<{[key: string]: EnumObjectDTO[]}> =>
        httpApi.get({
            url: urls.IMPROVEMENT_LOG_CAUSAL_FACTORS,
        });
    getLogEntryHistory = (id: string): Promise<LogChangeDTO[]> =>
        httpApi.get({
            url: urls.LOG_ENTRY_HISTORY(id),
        });
    reopenImprovementLog = (logId: string): Promise<PerformanceImprovementLogDTO> =>
        httpApi.patch({url: urls.IMPROVEMENT_LOG_REOPEN(logId)});
    changeImprovementLogStatus = ({
        id,
        logStatus,
    }: {
        id: string;
        logStatus: LogStatus;
    }): Promise<PerformanceImprovementLogDTO> =>
        httpApi.patch({url: urls.IMPROVEMENT_LOG_CHANGE_STATUS(id), data: {logStatus}});

    getPatientPerformanceImprovementLog = (patientId: number) =>
        httpApi.get<PerformanceImprovementLogDTO>({
            url: urls.PATIENT_PERFORMANCE_IMPROVEMENT_LOG(patientId),
        });
    getLevelsOfInvestigation = (): Promise<string[]> =>
        httpApi.get({
            url: urls.IMPROVEMENT_LOG_GET_LEVELS_OF_INVESTIGATION,
        });
    getLogEntryOptions = (entryPart: string) =>
        httpApi.get<string[]>({
            url: urls.GET_LOG_ENTRY_OPTIONS,
            queryParams: {entryPart},
        });

    getLogEntryData = (logEntry: string, disableGeneralNotify?: () => boolean) =>
        httpApi.get<PerformanceImprovementLogDTO>({
            url: urls.GET_LOG_ENTRY_DATA(logEntry),
            disableGeneralNotify,
        });

    getSubmissionTypes = () => httpApi.get<SubmissionTypes[]>({url: urls.SUBMISSION_TYPES});
    getClosedPerformanceImprovementLogReporters = (): Promise<PersonnelBasicDTO[]> =>
        httpApi.get({
            url: urls.LOG_REPORTERS,
            queryParams: {
                status: 'CLOSED',
            },
        });
    getInReviewPerformanceImprovementLogReporters = (): Promise<PersonnelBasicDTO[]> =>
        httpApi.get({
            url: urls.LOG_REPORTERS,
            queryParams: {
                status: 'IN_REVIEW',
            },
        });
    getNewPerformanceImprovementLogReporters = (): Promise<PersonnelBasicDTO[]> =>
        httpApi.get({
            url: urls.LOG_REPORTERS,
            queryParams: {
                status: 'READY_FOR_REVIEW,DRAFT',
            },
        });
    removePerformanceImprovementLogRelation = (
        parentLogId: string,
        relatedLogId: string
    ): Promise<PerformanceImprovementLogDTO> =>
        httpApi.delete({
            url: urls.REMOVE_RELATION(parentLogId, relatedLogId),
        });
    removePerformanceImprovementLogDuplication = (
        parentLogId: string,
        duplicatedLog: string
    ): Promise<PerformanceImprovementLogDTO> =>
        httpApi.delete({
            url: urls.REMOVE_DUPLICATION(parentLogId, duplicatedLog),
        });
    addRelatedLogs = (id: string, relatedLogIds: string[]): Promise<PerformanceImprovementLogDTO> =>
        httpApi.post({
            url: urls.IMPROVEMENT_LOG_RELATED_LOGS(id),
            data: {relatedLogIds},
            disableGeneralNotify: () => true,
        });
    addDuplicatedLogs = (id: string, duplicatedLogIds: string[]): Promise<PerformanceImprovementLogDTO> =>
        httpApi.post({
            url: urls.IMPROVEMENT_LOG_DUPLICATED_LOG(id),
            data: {duplicatedLogIds},
            disableGeneralNotify: () => true,
        });
    assignToReviewer = (patientId: string, reviewerEmail: string): Promise<AssignReviewerResponseDTO> => {
        const data: AssignReviewerDTO = {reviewerEmail};
        return httpApi.post({
            url: urls.ASSING_REVIEWER(patientId),
            data,
        });
    };
}

const performanceImprovementLogService = new PerformanceImprovementLogService();
export default performanceImprovementLogService;
