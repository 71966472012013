const PERFORMANCE_IMPROVEMENT_LOG = {
    IMPROVEMENT_LOG_CHANGE_STATUS: (id: string) =>
        `/performance-issue-tracker/v0/performanceImprovementLogs/${id}/status` as const,
    PERFORMANCE_IMPROVEMENT_LOG: '/performance-issue-tracker/v0/performanceImprovementLogs',
    PATIENT_PERFORMANCE_IMPROVEMENT_LOG: (patientId: number) =>
        `/performance-issue-tracker/v0/patients/${patientId}/log` as const,
    IMPROVEMENT_LOG_STATES: '/performance-issue-tracker/v0/lookup/eventTypes',
    IMPROVEMENT_LOG_SAVE_DRAFT: '/performance-issue-tracker/v0/performanceImprovementLogs/draft',
    IMPROVEMENT_LOG_COUNTER: '/performance-issue-tracker/v0/performanceImprovementLogs/active-count',
    IMPROVEMENT_LOG_GET_RESPONSIBLE_ACTORS: '/performance-issue-tracker/v0/lookup/responsibleActors',
    IMPROVEMENT_LOG_CAUSAL_FACTORS: '/performance-issue-tracker/v0/lookup/causalFactors',
    IMPROVEMENT_LOG_SAVE_REVIEW: (id: string) =>
        `/performance-issue-tracker/v0/performanceImprovementLogs/${id}/review` as const,
    IMPROVEMENT_LOG_RELATED_LOGS: (id: string) =>
        `/performance-issue-tracker/v0/performanceImprovementLogs/${id}/relatedLogs` as const,
    IMPROVEMENT_LOG_DUPLICATED_LOG: (id: string) =>
        `/performance-issue-tracker/v0/performanceImprovementLogs/${id}/duplicatedLogs` as const,
    IMPROVEMENT_LOG_CLOSE_LOGS: (id: string) =>
        `/performance-issue-tracker/v0/performanceImprovementLogs/${id}/closeLogs` as const,
    ADD_COMMENTS_TO_HISTORY_IMPROVEMENT_LOG: '/performance-issue-tracker/v0/comments',
    LOG_ENTRY_HISTORY: (id: string) =>
        `/performance-issue-tracker/v0/performanceImprovementLogs/${id}/history` as const,
    COMMENTS_HISTORY_IMPROVEMENT_LOG: (logId: string) => `/performance-issue-tracker/v0/comments/${logId}` as const,
    COMMENTS_HISTORY_IMPROVEMENT_LOG_COUNTER: (logId: string) =>
        `/performance-issue-tracker/v0/comments/count/${logId}` as const,
    SINGLE_IMPROVEMENT_LOG: (id: string) => `/performance-issue-tracker/v0/performanceImprovementLogs/${id}` as const,
    PATIENT_IMPROVEMENT_LOG_COUNTER: (patientId: string) =>
        `/performance-issue-tracker/v0/patients/${patientId}/active-count` as const,
    IMPROVEMENT_LOG_REOPEN: (logId: string) =>
        `/performance-issue-tracker/v0/performanceImprovementLogs/${logId}/reopen` as const,
    IMPROVEMENT_LOG_REVIEW_DATA: (logId: string) =>
        `/performance-issue-tracker/v0/performanceImprovementLogs/${logId}/reviewData` as const,
    IMPROVEMENT_LOG_ORIGINAL_DATA: (logId: string) =>
        `/performance-issue-tracker/v0/performanceImprovementLogs/${logId}/originalData` as const,
    IMPROVEMENT_LOG_GET_LEVELS_OF_INVESTIGATION: '/performance-issue-tracker/v0/lookup/levelsOfInvestigation',
    GET_LOG_ENTRY_OPTIONS: '/performance-issue-tracker/v0/performanceImprovementLogs/log-entry',
    GET_LOG_ENTRY_DATA: (logEntry: string) =>
        `/performance-issue-tracker/v0/performanceImprovementLogs/log-entry/${logEntry}`,
    SUBMISSION_TYPES: '/performance-issue-tracker/v0/lookup/submissionTypes',
    LOG_REPORTERS: '/performance-issue-tracker/v0/lookup/logReporter',
    REMOVE_RELATION: (parentLogId: string, relatedLogId: string) =>
        `/performance-issue-tracker/v0/performanceImprovementLogs/${parentLogId}/relatedLogs/${relatedLogId}`,
    REMOVE_DUPLICATION: (parentLogId: string, duplicatedLogId: string) =>
        `/performance-issue-tracker/v0/performanceImprovementLogs/${parentLogId}/duplicatedLog/${duplicatedLogId}`,
    ASSING_REVIEWER: (patientId: string) =>
        `/performance-issue-tracker/v0/performanceImprovementLogs/${patientId}/reviewer`,
} as const;

export default {
    ...PERFORMANCE_IMPROVEMENT_LOG,
};
